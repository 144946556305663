$(document).ready(function() {

  //определение ширины скролла для фансибокс
  // создадим элемент с прокруткой
  var div = document.createElement('div');
  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

// при display:none размеры нельзя узнать
// нужно, чтобы элемент был видим,
// visibility:hidden - можно, т.к. сохраняет геометрию
  div.style.visibility = 'hidden';
  document.body.appendChild(div);
  var scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  /*console.log( scrollWidth );*/


  if ($(window).width() >= 1200) {
    $(function () {
      $(window).scroll(function () {
        var top = $(document).scrollTop();
        var header = $(".header").height() + 72;
        /*console.log(hheader2)*/
        if (top < header) {
          $(".header").removeClass("fix")
        }


        else {

          $(".header").addClass("fix");
        }
      });
    });
  }
  if ($(window).width() <= 991) {
    $(".header__menu-toogle").click(function () {
      $(this).parent().toggleClass("active");
    });

  }
  jQuery(function($){
    $(document).mouseup(function (e){ // событие клика по веб-документу
      var div = $(".header__menu"); // тут указываем ID элемента
      if (!div.is(e.target) // если клик был не по нашему блоку
        && div.has(e.target).length === 0) { // и не по его дочерним элементам
       div.removeClass("active");
        //div.hide(); // скрываем его
      }
    });
  });


/*  ! function(i) {
    var o, n;
    i(".activity-item__title").on("click", function(e) {
      e.preventDefault();
      o = i(this).parents(".activity-item"), n = o.find(".activity-item__desc"),
        o.hasClass("active_block") ? (o.removeClass("active_block"),
          n.slideUp()) : (o.addClass("active_block"), n.stop(!0, !0).slideDown(),
          o.siblings(".active_block").removeClass("active_block").find(
            ".activity-item__desc").stop(!0, !0).slideUp())
    });
  }(jQuery);*/

  ! function(i) {
    var o, n;
    i(".activity-item").on("click", function(e) {
      e.preventDefault();
      o = i(this), n = o.find(".activity-item__desc"),
        o.hasClass("active_block") ? (o.removeClass("active_block"),
          n.slideUp()) : (o.addClass("active_block"), n.stop(!0, !0).slideDown(),
          o.siblings(".active_block").removeClass("active_block").find(
            ".activity-item__desc").stop(!0, !0).slideUp())
    });
  }(jQuery);



/*  $(".activity-items").each(function () {
    $(".activity-item").on("mouseover",function () {
      $(".activity-item").removeClass("active");
      $(this).addClass("active");
    });
  });*/

  //home btn down one

  $(".page-template-activity .header__menu .children ul li a").each(function () {
    $(".header__menu .children ul li a").addClass("link-down2");

  })
  $(".link-down").on("click", function (event) {
    event.preventDefault();
    var id  = $(this).attr('href'),
      top = $(id).offset().top;
    $('body,html').animate({scrollTop: top - 80}, 1500);
  });
  $(".link-down2").on("click", function (event) {
    event.preventDefault();
    var id2  = $(this).attr('href'),
      top2 = $(id2).offset().top;
    $('body,html').animate({scrollTop: top2 - 150}, 1500);
  });



  if($("body").hasClass("page-template-activity")){
     $('html,body').animate({
       scrollTop: $(window.location.hash).offset().top - 150
     });





    /* $(".header__menu .children ").on("click",'a', function (event) {
       event.preventDefault();
       var id  = $(this).attr('href'),
         top = $(id).offset().top;
       $('body,html').animate({scrollTop: top - 50}, 1500);
     });*/
    /* $(".header__menu .children a").click(function (event) {
       event.preventDefault();
       var id2  = $(this).attr('href'),
         top2 = $(id2).offset().top;
       $('body,html').animate({scrollTop: top2 - 50}, 1500);
     })*/

  }













/*  $(".header__menu ul li:nth-child(2) ul li a").click(function (e) {
    e.preventDefault();
    $('body').addClass('out');
    var t = $(this);
    setTimeout(function(){
      location.href = t.attr('href');
      $('body,html').animate({scrollTop: top - 50});
      }, 900);
  })*/
  //btn-to top
  $(function() {
    $('.btn-top').click(function() {
      $('body,html').animate({scrollTop:0},1200);
    });
  });

  /*slider home-banner*/
  $('.home-banner').slick({
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: false,
    autoplaySpeed:2500,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  /*slider activity*/
  $('.activity-sl').slick({
    dots: false,
    arrows:false,
    infinite: true,
    autoplay: false,
    autoplaySpeed:2500,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  });
  $('.project-sl').slick({
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: false,
    autoplaySpeed:2500,
    speed: 1500,
    rows:2,
    slidesPerRow:3,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 660,
        settings: {

          rows:1,
          slidesPerRow:1,
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 992,
        settings: {

          rows:1,
          slidesPerRow:2,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      },
      {
        breakpoint: 1200,
        settings: {

          rows:2,
          slidesPerRow:2,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  });


  /*trigger click slider*/
  $(".activity-sl-arrow__item.prev").click(function () {
    $('.activity-sl').slick('slickPrev');
  });
  $(".activity-sl-arrow__item.next").click(function () {
    $('.activity-sl').slick('slickNext');
  });


  var $homeabout_slider = $('.about__items')
  var homeabout_slider_settings = {
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: false,
    autoplaySpeed:1500,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 529,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      }
    ]
  };

  var $partners_slider = $('.partners-others')
  var partners_slider_settings = {
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: false,
    autoplaySpeed:1500,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 529,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      }
    ]
  };
// Init Slider
  $homeabout_slider.slick(homeabout_slider_settings);
  $partners_slider.slick(partners_slider_settings);
// Reslick only if it's not slick()
  $(window).on('resize', function() {
    if (!$homeabout_slider.hasClass('slick-initialized')) {
      return $homeabout_slider.slick(homeabout_slider_settings);
    }
    if (!$partners_slider.hasClass('slick-initialized')) {
      return $partners_slider.slick(partners_slider_settings);
    }
  });
});














































